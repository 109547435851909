<template>
  <div class="d-flex flex-row align-content-center align-items-center">
    <vs-checkbox
        v-model="row.selection"
        :disabled="disabled"
        color="primary"
        @change="onCheck"
    >
    </vs-checkbox>
    <div class="d-flex icon-background align-items-center justify-content-center">
      <div class="align-items-center justify-content-center" style="width: 22px; height: 22px">
        <d-svg-container
            :icon="icon"
            :svg-icon-style="{
            fill: '#0B2772',
            stroke : '#0B2772'
          }"
        />
      </div>
    </div>
    <span class="ml-3 black-col-title">
         {{ title }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    row: {
      type: Object,
      default: this,
    }
  },
  methods: {
    onCheck () {
      this.$emit('on:checkbox-selected', this.row);
    }
  },
  created () {
    this.$vs.theme({
      primary:'rgb(57,70,103)'
    });
  }
}
</script>
<style scoped>
/deep/ .vs-checkbox--input:checked+.vs-checkbox .vs-icon {
  font-family: 'Material Icons';
}
.checkbox-container {
  padding: 0px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #d6d6d6;
  border-radius: 3px;
  width: 18px;
  height: 18px;
}
.black-col-title  {
  font: normal normal 15px Avenir;
  letter-spacing: 0;
  color: #4D4F5C;
  opacity: 1;
}
.checkbox-container-active {
  padding: 2px 9px 0px 5px;
  background: #0B2772 0% 0% no-repeat padding-box;
  border-radius: 3px;
}

.checkbox-container-active:hover {
  background: #057799 0% 0% no-repeat padding-box;
}

.checkbox-container-inactive:hover {
  background: #bce6f3 0% 0% no-repeat padding-box;
}

.checkbox-container-inactive {
  padding: 0px 10px 1px 5px;
  background: transparent;
  border-radius: 3px;
}


.time-class-label-style-active {
  font: normal normal 500 18px Avenir;
  letter-spacing: 0px;
  color: #272727;
  opacity: 1;
}

.time-class-label-style-inactive {
  font: normal normal 300 16px Roboto-Regular;
  letter-spacing: 0px;
  color: #242e66;
  opacity: 1;
}

.icon-background {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  position: relative;
  height: 40px;
  background-color: #4d587773;
  border-radius: 8px
}

</style>
